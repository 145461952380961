
<template>
  <div class="foodlistcenter foodlistpadded" v-if="show">
    <div class="foodlistcenter foodlistgrid">

        <b-row class="foodlistrow">
            <b-col :key="index" v-for="col,index in foodListData" :cols="foodCols(foodListData[index])" class="foodlistsummary">
                <food-summary
                    :food="foodListData[index]"
                    :cart="cart"
                    v-on:adjustCart="adjustCart"
                ></food-summary>
            </b-col>
            <b-col v-if="foodListData.length == 0"><h1 class="foodlistNoMatch">Sorry no food or drink match that search</h1></b-col>
        </b-row>      
    </div>
  </div>
</template>

<script>

  import FoodSummary from './FoodSummary.vue'

  export default {
    props: {
      showList: Boolean,
      searchTerm: String,
      foodList: Array,
      cart:Object
    },
    data () {  
        return {
            search:''
        };    
    },       
    components: {
        FoodSummary
    },    
    mounted() {   
    },
    methods: { 
        adjustCart(wine,moveBy,type) {
          this.$emit('adjustCart', wine, moveBy,type);
        }      ,
        foodCols(food) {
          if (food.size == 3) {
            return "2"
          } else {
            return "4"
          }
        }

    },    
    computed: {      
      foodListData() {

        var foodList = [];
        this.foodList.forEach(food => {

          var searchMatch = false;
          if (this.searchTerm == '') { 
            searchMatch = true; 
          } else {
            if (food.name.toUpperCase().indexOf(this.searchTerm.toUpperCase()) > -1) {
              searchMatch = true; 
            }
          }

          if (searchMatch ) {
            foodList.push(food);
          }

          
        });

        return foodList;
      } ,    
      show: {
        get () {
          return this.showList
        }
      }
    }        
  }

</script>
<style>
    .foodlistpadded {
        border:1vh solid transparent;
    }
    .foodlistsummary {
      border-bottom: 10px solid transparent;
      padding-left:10px;
      padding-right:0;
    }
    .foodlistfixbar {
      position: sticky;   /* The magic */
      z-index: 1;         /* Ensure it stays on top of other player divs */
      top: 30px;           /* Where it should stick to */
      background-color: red;
      width:100%;
    }

    #sort{
        position: absolute;
        top: 0px;
        right:0;
        width: calc(10%);
        z-index: 2;
        background:transparent;
        text-align: center;

    }   
    .foodlistNoMatch {
        color:white;
    }
    .sorticon {
      font-size: 5vh;
    }
    .winelistNoMatch {
      color:white;
      padding:20px;
    }

</style>