
<template>
  <div class="center">
    <b-modal id="bv-adjust-cart-modal"  no-close-on-backdrop v-model="show" size="xl">
      <template #modal-title>
        <h4 class="not-margin">
          Set Tab
        </h4>
      </template>


      <div class="con-form">

        <label for="form-name">Tab Limit</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-name"
            v-model="$v.form.tab.$model"
            placeholder="Tab"
            :state="validateState('tab')"
            type="number"
            required
          ></b-form-input>
        </b-input-group>        

      </div>

      <template #modal-footer>
        <div class="footer-dialog">
            <b-row>
                <b-col>            
                    <b-button block @click="handleSubmit" class="startCartSuccessButton">
                        Save
                    </b-button>
                </b-col>
                <b-col>            
                    <b-button block @click="handleCancel">
                        Cancel
                    </b-button>
                </b-col>
            </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  import { validationMixin } from 'vuelidate';
  import { required} from "vuelidate/lib/validators";

  export default {
    mixins: [validationMixin], 
    props: {
      showdialog: Boolean,
      record:Object,
    },   
    data() {
      return {
        form: {
          tab: '0'
        }
      }
    },   
    validations: {
      form: {
        tab: {
          required
        },
      }
    },       
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },      
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()

        console.log(valid)

        this.terminalIdState = valid
        return valid
      },      
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },      
      handleSubmit() {
        this.$v.form.$touch();
        console.log(this.$v.form);
        if (this.$v.form.$anyError) {
          return;
        }
        this.$emit('doSaveTab', this.form)
        // Hide the modal manually

      },
      handleCancel() {
        this.$emit('doCancelTab')

      }
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        },
        set() {
        }
      },
    },
    watch: {
        record: function() {
            console.log('this.record.override');
            console.log(this.record.override);
            this.form = this.record.override;
        }, 
    }     
  }
</script>

<style>
    #bv-start-cart-customer-modal .modal-content {
        background-color: #2e3442 !important;
    }


    #bv-start-cart-customer-modal .modal-footer {
        border-top: 1px solid #2e3442;
    }


    #bv-start-cart-customer-modal .modal-header {
        border-bottom: 1px solid #2e3442;
    }
    #bv-start-cart-customer-modal h4 {
        color:white;
    }

    .bv-start-cart-customer-modalerrorMessage {
      color:#cc0000;
      font-weight: 700;
    }

    .startCartSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
    }


</style>