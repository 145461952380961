
<template>
  <div class="center padded" v-if="show">
    <div class="center grid">
        
        <b-row>
            <b-col :key="index" v-for="col,index in wineListData" cols="12" class="winesummary">
                <wine-summary 
                    :wine="wineListData[index]"
                    :cart="cart"
                    :grapeVariety="grapeVariety"
                    :wineries="wineries"
                    :countries="countries"
                    :winesizes="winesizes"
                    v-on:showMoreClick="showWine"
                    v-on:adjustCart="adjustCart"
                ></wine-summary>
            </b-col>
            <b-col v-if="wineListData.length == 0"><h1 class="winelistNoMatch">Sorry no wines match that search or are available</h1></b-col>
        </b-row>     
    </div>
    <br/><br/><br/><br/><br/><br/><br/>
  </div>
</template>

<script>

  import WineSummary from './WineSummary.vue';

  export default {
    props: {
      showList: Boolean,
      searchTerm: String,
      wineList: Array,
      grapeVariety: Array,
      countries: Array,
      wineries:Array,
      winesizes: Array,
      wineFilters: Object,
      cart:Object
    },
    data () {  
        return {
            loaded:false,
            search:''
        };    
    },       
    components: {
        WineSummary,
    },    
    mounted() {   
    },
    methods: { 
        showWine(wine) {
          this.$emit('showMoreClick', wine)
        },        
        adjustCart(wine,moveBy,type) {
          this.$emit('adjustCart', wine, moveBy,type);
        }
    },    
    computed: {
      wineListData() {

        var wineList = [];
        this.wineList.forEach(wine => {


          var vintageFilter = false;
          if (this.wineFilters.vintageSelected.length > 0 ) {
            this.wineFilters.vintageSelected.forEach(filter => {
                if (parseInt(filter) == parseInt(wine.vintage)) {
                  vintageFilter = true;
                }
            });
          } else { vintageFilter = true; }


          var typeFilter = false;
          if (this.wineFilters.winetypeSelected.length > 0 ) {
            this.wineFilters.winetypeSelected.forEach(filter => {
                if (parseInt(filter) == parseInt(wine.wine_style_id)) {
                  typeFilter = true;
                }
            });
          } else { typeFilter = true; }

          var sizeFilter = false;
          if (this.wineFilters.winesizeSelected.length > 0 ) {
            this.wineFilters.winesizeSelected.forEach(filter => {
                if (parseInt(filter) == parseInt(wine.wine_size_id)) {
                  sizeFilter = true;
                }
            });
          } else { sizeFilter = true; }

          var grapeFilter = false;
          if (this.wineFilters.grapeSelected.length > 0 ) {
            this.wineFilters.grapeSelected.forEach(filter => {
                if (parseInt(filter) == parseInt(wine.grape_variety.id)) {
                  grapeFilter = true;
                }
            });
          } else { grapeFilter = true; }

          var countryFilter = false;

          if (this.wineFilters.countrySelected.length > 0 ) {
            this.wineFilters.countrySelected.forEach(filter => {
              if (wine.winery) {
                if (wine.winery.country) {
                  if (parseInt(filter) == parseInt(wine.winery.country)) {
                    countryFilter = true;
                  }
                }
              }
            });
          } else { countryFilter = true; }

          var priceFilter = false;
          if (this.wineFilters.winePriceRange.length > 0 ) {

              if (parseInt(this.wineFilters.winePriceRange[0]) == 0 && this.wineFilters.winePriceRange[1] == 10000) {
                  priceFilter = true;
              }            
              
              if ( parseInt(this.wineFilters.winePriceRange[0]) < parseFloat(wine.retail_price) && parseFloat(this.wineFilters.winePriceRange[1]) > parseFloat(wine.retail_price) ) {
                priceFilter = true;
              }
          } else { sizeFilter = true; }


          var searchMatch = false;
          if (this.searchTerm == '') { 
            searchMatch = true; 
          } else {

            if (wine.name.toUpperCase().indexOf(this.searchTerm.toUpperCase()) > -1) {
              searchMatch = true; 
            }
          }


          var notSoldOut = false;
          if ( parseInt(wine.released) > 0 ) {
            notSoldOut = true;
          }

          if (vintageFilter && searchMatch && typeFilter && sizeFilter && notSoldOut && priceFilter && countryFilter && grapeFilter) {
            wineList.push(wine);
          }

          
        });


        return wineList;
      } ,    
      show: {
        get () {
          return this.showList
        }
      }
    }        
  }

</script>
<style>
    .padded {
    }
    .winesummary {
      border: 10px solid transparent;
      border-top: 0;
      border-bottom: 20px solid transparent;
    }
    .fixbar {
      position: sticky;   /* The magic */
      z-index: 1;         /* Ensure it stays on top of other player divs */
      top: 30px;           /* Where it should stick to */
      background-color: red;
      width:100%;
    }

    #sort{
        position: absolute;
        top: 0px;
        right:0;
        width: calc(10%);
        z-index: 2;
        background:transparent;
        text-align: center;

    }   
    .sorticon {
      font-size: 5vh;
    }
    .winelistNoMatch {
      color:white;
      padding:20px;
    }
</style>