
<template>
  <div class="center padded">
    <div class="wineSummaryHolder">
        <b-row>
            <b-col cols="3">  

                <div class="wineSummaryImagepadding">
                  <div class="wineSummaryImageholder">
                      <img :src="getFirstImage(wine)" class="wineSummaryImage"/>
                      <div class="bigPlus">+</div>
                  </div>
                </div>
            </b-col>
            <b-col cols="9">
              <div class="wineSummaryDescptionHolder">
                    <div class="wineSummaryDescption wineSummaryDescptionBoldtitle">{{getDescription(wine)}}</div>
                    <div class="wineSummaryDescption wineSummaryDescptionSubBoldtitle">{{getWinary(wine)}}</div>
                    <div class="wineSummaryDescption">Country / Region : <b>{{getCountryRegion(wine)}}</b></div>
                    <div class="wineSummaryDescption">Vintage: <b>{{wine.vintage}}</b></div>
                    <div class="wineSummaryDescption">Grape: <b>{{getGrapeVariety(wine)}}</b></div>
                    <div class="wineSummaryDescption">Size: <b>{{getWineSize(wine)}}</b></div>
                    
                    <b-row>
                      <b-col cols="7">
                        <div class="wineSummaryPrice">Price : {{getPrice(wine)}} <br/><small class="wineSummaryStock">( {{wine.released }} in stock)</small></div>
                      </b-col>
                      <b-col cols="5" class="wineSummaryCartActionsholder">
                        <b-row class="wineSummaryCartActions">

                          <b-col  @click="addWine(wine,-1)">
                            <div class="wineQtyAdjust">-</div>
                          </b-col>
                          <b-col>
                            <div class="wineQty"  style="text-align:center">{{getCartQty(wine)}}</div>
                          </b-col>
                          <b-col  @click="addWine(wine,1)">
                            <div class="wineQtyAdjust" style="text-align:right">+</div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
              </div>
            </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>


  export default {
    props: {
      wine: Object,
      cart: Object,
      grapeVariety: Array,
      countries: Array,
      winesizes: Array,
      wineries:Array,
    },   
    data () {  
        return {
            imagePath:process.env.VUE_APP_DEFAULT_IMAGE_PATH
        };    
    },       
    methods: {
        showWine(wine) {
          console.log('showWine');
          this.$emit('showMoreClick', wine)
        },
        getFirstImage(wine) {
            if (wine.images) {
              if (Object.keys( wine.images ).length  == 0) {
                  return '/img/nowineimage.jpg';
              } else {
                  return this.imagePath + wine.images[0].name;
              }
            } else {
                  return '/img/nowineimage.jpg';
            }

        },
        getDescription(wine) {            
            var wineName = wine.name;

            return wineName;
        },
        getWineSize(wine){
          
          var retVal = 'n/a';
          this.winesizes.forEach(winesize => {

            if (winesize.id == wine.wine_size_id) {
              retVal = winesize.name + 'ml';
            }
            
          });

          return retVal;
        },
        getGrapeVariety(wine) {
          var retVal = 'n/a';
          this.grapeVariety.forEach(grapeVariety => {

            if (grapeVariety.id == wine.grape_variety.id) {
              retVal = grapeVariety.name;
            }
            
          });

          return retVal;
        },

        getCountryRegion(wine) {

          var retVal = 'n/a';

          if (wine.winery) {
            if (wine.winery.country) {
              
              this.countries.forEach(country => {

                  if (country.id == wine.winery.country) {
                    retVal = country.name;

                    if (wine.winery.region) {
                        country.regions.forEach(region => {                      
                          if (region.id == wine.winery.region) {
                            retVal = retVal + '/' + region.name;
                          }
                        });
                    }

          

                  }

              });

            }
          }

          this.wineries.forEach(winery => {

            var tempDescption = '';

            if (winery.id == wine.winery.id) {

              if (winery.country)  {
                  tempDescption = tempDescption + winery.country.name;
              }
              if (winery.region)  {
                  tempDescption = tempDescption + ' / ' + winery.region.name;
              }
            }

            if (tempDescption != '') {
              retVal = tempDescption;
            } 

          });

          return retVal;
        },

        getWinary(wine) {

          var retVal = 'n/a';

          if (wine.winery) {
            if (wine.winery.name) {
              retVal = wine.winery.name;
            }
          }
          return retVal;
        },


        getPrice(wine) {
            var winePrice = 'SOLD OUT';

            if ( parseInt(wine.released) > 0 ) {
              winePrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(wine.retail_price));
            }

            return winePrice;
        },       
        addWine(wine,moveby) {
          console.log(wine,moveby);
          this.$emit('adjustCart', wine, moveby , 'wine');
        },
        getCartQty(wine) {

          var retStr = "0";
          this.cart.items.forEach(item => {
            if (item.id == wine.id) {
              retStr = item.qty;
            }
            
          });
          return retStr;
        }
    }
  }

</script>


<style>

  .wineSummaryHolder {
    background-color: #2e3444;
    border-radius: 1vw;
    padding:0.6vw;
  }

  .wineSummaryImage{
    width:100%;
    object-fit: cover;
  }
  .wineSummaryImagepadding {
    border:1vw solid transparent;
  }
  .wineSummaryImageholder {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    box-sizing: border-box;
    position: relative;
    border-radius: 10%;  
  }
  .wineSummaryImageholder img {
    width: 100%;
    vertical-align: top;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .wineSummaryDescptionHolder {
    border:1vw solid transparent;
  }

  .wineSummaryCartActionsholder{
    padding-top:10px;
    padding-right:20px;
  }
  .wineSummaryDescption {
      color:#9a9da7;
      font-weight: 500;
      font-size:1vw;
  }
  .wineSummaryDescption b {
      color:white;
  }

  .wineSummaryPrice {
      padding-top:10px;
      color:white;
      font-weight: 700;
      font-size:1.4vw;
  }
  
  .wineSummaryDescptionBoldtitle {
      color:white;
      font-size:1.6vw;
  }

  .wineSummaryDescptionSubBoldtitle {
      color:white;
      font-size:1.2vw;
  }

  .winedescriptionholder {
    display: flex;
    flex-direction: column;
    border-bottom:1vw solid transparent;
    border-right:1.2vw solid transparent;
  }

  .wineSummaryCartActions {
      margin-top: auto !important;
      border: 2px solid #7f838e !important;
      border-radius: 10px !important;
      color:white;

  }
  .wineSummaryStock {
    font-size: 0.7vw;
  }


  .wineQty {
    font-size: 1.4vw;
  }
  .wineQtyAdjust {
    font-size: 1.6vw;
    cursor: pointer;

  }
</style>
