<template>

    <div class="paymentholder">

        <b-alert
            :show="dismissCountDown"
            fade
            variant="danger"
            @dismiss-count-down="countDownChanged"
        >
            Error : {{errorMessage}}
        </b-alert>  


        <div class="loader" v-if="loading">Loading...</div>


        <div v-if="!loading">{{cart.payments}}
            <center v-if="paymentID=='' && cart.payments == 0" class="startCartSuccessPAdding"><b-button block size="lg" class='startCartSuccessButton startCartSuccessButtonBig' v-on:click="sendpayment()">
                Take the FULL payment from Customer : {{formatPrice(grandTotal())}}
            </b-button></center>           
            <center v-if="paymentID=='' && cart.payments > 0" class="startCartSuccessPAdding"><b-button block size="lg" class='startCartSuccessButton startCartSuccessButtonBig' v-on:click="sendpayment()">
                Take the OUTSTANDING payment from Customer : {{formatPrice(grandTotal())}}
            </b-button></center>                 
            <center v-if="paymentID==''" class="startCartSuccessPAdding"><b-button block size="lg" class='startCartSuccessButton startCartSuccessButtonBig' v-on:click="splitpayment()">
                Take a Split payment
            </b-button></center>            
            <center v-if="debug && paymentID!=''" class="startCartSuccessPAdding"><b-button block size="lg" class='startCartSuccessButton startCartSuccessButtonBig' v-on:click="testpayment()">
                Dummy payment success
            </b-button></center>            
            <center v-if="paymentID!=''" class="startCartSuccessPAdding"><b-button block size="lg" class='startCartSuccessButton startCartSuccessButtonBig' v-on:click="cancelpayment()">
                Cancel Payment
            </b-button></center>            
        </div>

        <cart-split-modal
          :splitPaymentAmount="splitPrice"
          :showdialog="cartSplitVisible"
          v-on:doCancelCartSplit="doCancelCartSplit"
          v-on:doSaveCartSplit="doSaveCartSplit"
        />


    </div>

</template>

<script>
  
  import { HTTP } from '@/plugins/http-common';
  import CartSplitModal from './CartSplitModal.vue';  

  export default {
    props: {
      cart:Object,
      pageVisble:Boolean
    },
    components:{
      CartSplitModal,
    },    
    data:() => ({
        callback_url: process.env.VUE_APP_SQUAREUP_CALLBACK,
        client_id: process.env.VUE_APP_SQUAREUP_ID,
        sparams:[],
        paymentID:'',
        cartID:'',
        debug:false,
        loading:false,
        interval:null,

        errorMessage:'',
        dismissCountDown:0,
        splitPrice:0,

        cartSplitVisible: false,

    }),      
    beforeDestroy: function() {
        clearInterval(this.interval);
    },
    methods: {
        dummySU() {
            window.location =    (process.env.VUE_APP_BACKEND_ENDPOINT) + 'squareuppos?data=%7B%22status%22:%22ok%22,%22state%22:%22'+this.cart.orderNo+'%22,%22transaction_id%22:%2261Xt4mb74NmLHwccXnVFmBpeV%22,%22client_transaction_id%22:%2271FC5E95-3A73-4BB2-B6E2-3E1FD42A12DA%22%7D';
        },
        checkPayment() {
          HTTP.post(
            'api/squareup/checkpayment',
            {
              paymentID: this.paymentID
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }             
            )
            .then(response => {
                console.log(response);

                if (!response.data.continue) {

                    if (response.data.message != 'COMPLETED') {
                        
                        clearInterval(this.interval);
                        this.$emit('paymentStatusError', 'Payment has stopped : ' + response.data.message)


                        this.$nextTick(() => {
                            this.$router.push({ name: 'home' });       
                        })                             

                    } else {
                        clearInterval(this.interval);
                        if (response.data.outstanding <= 0) {
                          window.location.href= '/paid/' + this.cartID;
                        } else {
                          this.paymentID ='';
                          this.$emit('partPaymentSuccess', response.data.paymentSofar);                      
                        }
                        
                    }

                }
            });
        },
        cancelpayment() {

          this.loading = true;

          HTTP.post(
            'api/squareup/cancelpayment',
            {
              paymentID: this.paymentID
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }             
            )
            .then(response => {
                console.log(response);
                this.loading = false;
            });

        },
        testpayment() {

            clearInterval(this.interval);

            this.loading = true;
            HTTP.post(
            'api/squareup/testpayment',
            {
              paymentID: this.paymentID
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }             
            )
            .then(response => {
                console.log(response);

                this.loading = false;                
                window.location.href= '/paid/' + this.cartID;     
            
            });

        },
        
        

        splitpayment() {

          this.splitPrice = 0;
          this.$nextTick(() => {
            this.splitPrice = this.getHalfOfTotal(); 
          })           

          this.cartSplitVisible = true;
        },

        doCancelCartSplit() {
          this.cartSplitVisible = false;
        },

        doSaveCartSplit(form) {
          this.cartSplitVisible = false;
          this.$nextTick(() => {
            this.sendpayment(form.splitPaymentAmount); 
          })  
        },






        sendpayment(splitPayment = 0) {

         this.loading = true;

          HTTP.post(
            'api/squareup/createpayment',
            {
              cart: this.cart,
              splitPayment: splitPayment
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }             
            )
          .then(response => {
            
            console.log(response);
            this.paymentID = response.data.payment;
            this.cartID = response.data.cart;

            this.loading = false;

            // start the timer
            this.interval = setInterval(function () {
                this.checkPayment();
            }.bind(this), 4000);             

          })
          .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Create Payment' + e;
            this.loading = false;
   
          })

        },
        grandTotal() {

            var grandTotal = this.totalPrice();
            var serviceCharge = this.serviceCharge();
            

            if (this.cart.serviceCharge) {
                grandTotal = ( grandTotal + serviceCharge ) - this.cart.payments ;
            }

            console.log('payments', this.cart);

            if (grandTotal == 0) {
                    this.$router.push({ name: 'home' });
            }

            return grandTotal;

        },


        getHalfOfTotal() {

          var splitPrice = parseFloat(  (parseInt((this.grandTotal()/2)*100) /100 ) );

          return splitPrice;

        },

        totalPrice() {

            var totalPrice = 0;

            this.cart.items.forEach(shoppingcartItem => {
                totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
            });

            if (this.cart.pricesOverride) {
                totalPrice = parseFloat(this.cart.override.itemTotal);
            }

            return totalPrice;

        },
        
        
        serviceCharge() {

            var totalPrice = this.totalPrice();

            var serviceCharge = totalPrice  * parseFloat(parseFloat(process.env.VUE_APP_SERVICE_CHARGE) / 100);

            if (this.cart.pricesOverride) {
                serviceCharge = parseFloat(this.cart.override.serviceCharge);
            }            
           
            return serviceCharge;

        },


        formatPrice(value) {
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(value));
        },

        getNotes() {

            var notes = '';
            this.cart.items.forEach(cartItem => {
                if (notes != '') { notes = notes +', '}
                notes = notes + cartItem.qty  + cartItem.name;
            });
            return notes;

        },      
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },        
    },
    computed: {
        show: {
            get () {
                return this.pageVisble
            }
        },
    }
  }

</script>

<style>
  .paymentholder {
    border:1vh solid transparent;
  }
  .startCartSuccessButtonBig {
      padding:10vh;
      font-size:36px;
  }
  .startCartSuccessPAdding {
      border-top:5vh solid transparent;
  }
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #131720;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


</style>