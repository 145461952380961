
<template>
  <div class="center">
    <b-modal id="bv-adjust-cart-modal"  no-close-on-backdrop v-model="show" size="xl">
      <template #modal-title>
        <h4 class="not-margin">
          Select the Table you wish to Merge
        </h4>
      </template>


      <div class="con-form">

          <b-row  :key="index" v-for="col,index in mergeList">
              <b-col cols="12" v-if="mergeOrderTarget != mergeList[index].id && mergeList[index].data.items.length > 0">
                    <b-button block size="lg" class='mergeOrderSuccessButton' v-on:click="mergeOrderSelected(mergeList[index].id)">
                        Table : {{mergeList[index].table_name}} <br/> <br/>
                        Order Details: <br/>
                        <div  :key="index2" v-for="col2,index2 in mergeList[index].data.items" >
                            {{mergeList[index].data.items[index2].qty}}x{{mergeList[index].data.items[index2].name}}<br/>
                        </div>
                    </b-button>&nbsp;                        
              </b-col>
          </b-row>
    

      </div>

      <template #modal-footer>
        <div class="footer-dialog">
            <b-row>
                <b-col>            
                    <b-button block @click="handleCancel">
                        Cancel
                    </b-button>
                </b-col>
            </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  export default {
    props: {
      showdialog: Boolean,
      cartList:Array,
      mergeOrderTarget:Number,

    },   
    data() {
      return {
        mergeList: []
      }
    },   
    methods: {
      handleCancel() {
        this.$emit('doCancelMergeOrder')
      },
      mergeOrderSelected(cartid) {
        this.$emit('mergeOrderSelected',cartid)
      },
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        },
        set() {
        }
      },
    },
    watch: {
        cartList: function() {
            console.log(this.cartList);
            this.mergeList = this.cartList;
        }, 
    }     
  }
</script>

<style>
    #bv-start-cart-customer-modal .modal-content {
        background-color: #2e3442 !important;
    }


    #bv-start-cart-customer-modal .modal-footer {
        border-top: 1px solid #2e3442;
    }


    #bv-start-cart-customer-modal .modal-header {
        border-bottom: 1px solid #2e3442;
    }
    #bv-start-cart-customer-modal h4 {
        color:white;
    }

    .bv-start-cart-customer-modalerrorMessage {
      color:#cc0000;
      font-weight: 700;
    }

    .mergeOrderSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
    }

    .mergeOrderSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
      font-size: 0.8vw !important;
      padding-left:0.5vw;
      padding-right:0.5vw;      
      width:100%
    }  
</style>