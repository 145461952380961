
<template>
  <div class="cartpaidpadded">
      <div v-if="show">
        <b-row v-if="cart.customer">
            <b-col cols="6">
                <h4>Payment : {{cart.customer.customerName}}<br/><br/></h4>
            </b-col>
        </b-row>

            <b-row class="">
                <b-col cols="7">
                    <h4>Product Info</h4>
                </b-col>
                <b-col cols="2">
                    <h4>Quantity</h4>
                </b-col>
                <b-col cols="3">
                    <h4>Price</h4>                    
                </b-col>
            </b-row>
            <b-row :key="index" v-for="col,index in cart.items" class="">

                <b-col cols="7">

                    <h4>{{cart.items[index].name}}</h4>
                    
                </b-col>
                <b-col cols="2" class="">

                    <h4>{{ cart.items[index].qty }}</h4>
                </b-col>
                <b-col cols="3">
                    <h4>{{getTotalPrice(cart.items[index])}}</h4>                    
                </b-col>
            </b-row>

            <b-row class="shoppingcartTotalItem noBorder cartPushin">
                <b-col cols="4"></b-col>
                <b-col cols="3" class="cartGreyText">
                    <h4>Sub total</h4>
                </b-col>
                <b-col cols="2">
                    
                </b-col>
                <b-col cols="3">
                    <h4>{{formatPrice(totalPrice)}}</h4>
                </b-col>
            </b-row>
            <!--
            <b-row  class="shoppingcartTotalItem noBorder cartPushin">
                <b-col cols="4"></b-col>
                <b-col cols="5" class="cartGreyText">
                    <h4>Tax (VAT {{taxPercent}}%)</h4>
                </b-col>
                <b-col cols="3">
                    <h4>{{formatPrice(totalTax)}}</h4>
                </b-col>
            </b-row>
            -->
            <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="cart.serviceCharge">
                <b-col cols="4"></b-col>
                <b-col cols="5" class="cartGreyText">
                    <h4>Service Charge ({{servicePercent}}%)</h4>
                </b-col>
                <b-col cols="3">
                    <h4>{{formatPrice(serviceCharge)}}</h4>
                </b-col>
            </b-row>
            <b-row  class="shoppingcartTotalItem noBorder cartPushin">
                <b-col cols="4"></b-col>
                <b-col cols="5" class="cartGreyText">
                    <h4>Total</h4>
                </b-col>
                <b-col cols="3">
                    <h4>{{formatPrice(grandTotal)}}</h4>
                </b-col>
            </b-row>
            <b-row class="shoppingcartTotalItem noBorder cartPushin">
                <b-col cols="12">
                    <router-link to="/"><b-button block size="lg" class="startCartSuccessButton">
                        Continue<font-awesome-icon icon="store-alt" />
                    </b-button></router-link>                   
                </b-col>
            </b-row>
            <b-row v-if="cart.pricesOverride">
                <b-col cols="5"></b-col>
                <b-col cols="5">
                    <h2>Price Overridden : {{cart.override.notes}} </h2>
                </b-col>
            </b-row>            

      </div>

  </div>
</template>

<script>

  export default {
    props: {
      paymentList: Array,
      wineList: Array,
    },
    data () {  
        return {
            cart: [],
            show:false,
            taxPercent: process.env.VUE_APP_VAT_RATE,
            servicePercent: process.env.VUE_APP_SERVICE_CHARGE            
        };    
    },       
    components: {
    },     
    mounted() {   

    },
    methods: { 

        formatPrice(value) {
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(value));
        },        
        getFirstImage(cart) {

            var retimage = '/img/nowineimage.jpg';
            if (cart.type == 'food') {
                retimage = '/img/';
            }

            this.wineList.forEach(wine => {
                if (wine.id == cart.id) {

                    if (wine.images) {
                        if (Object.keys( wine.images ).length > 0) {
                            retimage = wine.images[0].name;
                        }
                    }
                }
                
            });

            return retimage;

        },       
          getTotalPrice(shoppingcartItem) {
            var winePrice = '';

            if ( parseInt(shoppingcartItem.qty) > 0 ) {
              winePrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
            }

            return winePrice;
        },       
        getPrice(shoppingcartItem) {
            var winePrice = '';

            if ( parseInt(shoppingcartItem.qty) > 0 ) {
              winePrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(shoppingcartItem.price));
            }

            return winePrice;
        },      

    },    
    computed: {      

       currentCart() {
            return this.cart
        },
        totalPrice() {

            var totalPrice = 0;
            this.cart.items.forEach(shoppingcartItem => {
                totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
            });


            if (this.cart.pricesOverride) {
                totalPrice = parseFloat(this.cart.override.itemTotal);
            }


            return totalPrice;

        },
        totalPriceFood() {

            var totalPrice = 0;
            this.cart.items.forEach(shoppingcartItem => {
                if (shoppingcartItem.type != 'wine') {
                    totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
                }                
            });


            return totalPrice;

        },            
        totalItems() {

            var totalItems = 0;

            var cart = this.cart;

            if (cart.length > 0) {
                cart.items.forEach(shoppingcartItem => {
                    totalItems = totalItems + parseInt(shoppingcartItem.qty);
                });

            }

            return totalItems;

        },
        totalTax() {

            var totalPrice = this.totalPrice;

            var calcedTAX = ( totalPrice / (100 + parseFloat(process.env.VUE_APP_VAT_RATE)) ) * parseFloat(process.env.VUE_APP_VAT_RATE);

            return calcedTAX;

        },
        serviceCharge() {

            var totalPrice = this.totalPrice;

            var serviceCharge = totalPrice  * parseFloat(parseFloat(process.env.VUE_APP_SERVICE_CHARGE) / 100);
            if (this.cart.pricesOverride) {
                serviceCharge = parseFloat(this.cart.override.serviceCharge);
            }            
                        
            return serviceCharge;

        },
        grandTotal() {

            var grandTotal = this.totalPrice;
            var serviceCharge = this.serviceCharge;
            

            if (this.cart.serviceCharge) {
                grandTotal = grandTotal + serviceCharge;
            }

            return grandTotal;

        },        


    },
    watch: {
        paymentList: function() {
            console.log(this.paymentList)
            this.paymentList.forEach(payment => {

                console.log(this.$route.params.id, payment.id);

                if (parseInt(this.$route.params.id) == payment.id ) {
                    this.cart = payment.data;
                    this.show = true;
                }
            });            
        }, 
    }          
  }

</script>
<style>
    .cartpaidpadded {
        width: 80%; 
        margin: 0 auto; 
    }
    .cartpaidpadded h4, .cartpaidpadded h2 {
        color:white;
    }
    .cartlistHeader {
        color:white;
    }
    .cartlistBody {
      color:white;
    }
    .cartlistBody tr:hover {
      color:white !important;
      background-color: blue !important;
    }
    .cartlistActionButton {
        background-color: #b0996f !important;
        color: white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }   
    .cartlistTable .table{
      width:100%
    } 


</style>