
<template>
  <div class="center">
    <b-modal id="bv-start-cart-modal" no-close-on-backdrop v-model="show" size="xl">
      <div class="con-form">

          <div class="tableImageDiv" :style="'background:url(' + tableimg + ');background-size: cover;'">
            <div id="table1" class="tableBox" v-bind:class="{ activeTable: activeOrder('1') }" v-on:click="tableSelect('1')">
                <div v-if="activeOrder('1')" class="tableStatus">ITEMS : {{activeOrderNoItem('1')}}</div>
            </div>
            <div id="table2" class="tableBox" v-bind:class="{ activeTable: activeOrder('2') }" v-on:click="tableSelect('2')">
                <div v-if="activeOrder('2')" class="tableStatus">ITEMS : {{activeOrderNoItem('2')}}</div>
            </div>
            <div id="table3" class="tableBox" v-bind:class="{ activeTable: activeOrder('3') }" v-on:click="tableSelect('3')">
                <div v-if="activeOrder('3')" class="tableStatus">ITEMS : {{activeOrderNoItem('3')}}</div>
            </div>
            <div id="table4" class="tableBox" v-bind:class="{ activeTable: activeOrder('4') }" v-on:click="tableSelect('4')">
                <div v-if="activeOrder('4')" class="tableStatus">ITEMS : {{activeOrderNoItem('4')}}</div>
            </div>
            <div id="table5" class="tableBox" v-bind:class="{ activeTable: activeOrder('5') }" v-on:click="tableSelect('5')">
                <div v-if="activeOrder('5')" class="tableStatus">ITEMS : {{activeOrderNoItem('5')}}</div>
            </div>
            <div id="table6" class="tableBox" v-bind:class="{ activeTable: activeOrder('6') }" v-on:click="tableSelect('6')">
                <div v-if="activeOrder('6')" class="tableStatus">ITEMS : {{activeOrderNoItem('6')}}</div>
            </div>
            <div id="table7" class="tableBox" v-bind:class="{ activeTable: activeOrder('7') }" v-on:click="tableSelect('7')">
                <div v-if="activeOrder('7')" class="tableStatus">ITEMS : {{activeOrderNoItem('7')}}</div>
            </div>
            <!--
            <div id="table8" class="tableBox" v-bind:class="{ activeTable: activeOrder('8') }" v-on:click="tableSelect('8')">
                <div v-if="activeOrder('8')" class="tableStatus">ITEMS : {{activeOrderNoItem('8')}}</div>
            </div>
            <div id="table9" class="tableBox" v-bind:class="{ activeTable: activeOrder('9') }" v-on:click="tableSelect('9')">
                <div v-if="activeOrder('9')" class="tableStatus">ITEMS: {{activeOrderNoItem('9')}}</div>
            </div>
            -->
          </div><br/>
          <center>
            <b-button block size="lg" class='startCartSuccessButton' v-on:click="tableSelect('Terrace')">
              Terrace <span v-if="activeOrder('Terrace')">(Open Order {{activeOrderNoItem('Terrace')}})</span><font-awesome-icon icon="umbrella-beach" />
            </b-button>&nbsp;
            <b-button block size="lg" class='startCartSuccessButton' v-on:click="tableSelect('Board Room')">
              Board Room <span v-if="activeOrder('Board Room')">(Open Order {{activeOrderNoItem('Board Room')}})</span><font-awesome-icon icon="door-closed" />
            </b-button>&nbsp;
            <b-button block size="lg" class='startCartSuccessButton' v-on:click="tableSelect('Till')">
              Retail Shop Till <span v-if="activeOrder('Till')">(Open Order {{activeOrderNoItem('Till')}})</span> <font-awesome-icon icon="store-alt" />
            </b-button>&nbsp;
            <b-button block size="lg"  @click="cancelCart()">
              Cancel

            </b-button>
          </center>

          

      </div>

    </b-modal>
  </div>
</template>


<script>

  import { HTTP } from '@/plugins/http-common';
  import tableImage from '@/assets/tables.png';

  export default {
    props: {
      showdialog: Boolean,
      tables: Array,
      opencarts: Array,
    },   
    data() {
      return {
        tableimg:  tableImage
      }
    },   
    methods: {
        activeOrderNoItem(tableno) {
            var retVal = 0;
            this.opencarts.forEach(itemCart => {
                if (tableno == itemCart.table_name) {
                    itemCart.data.items.forEach(dataitem => {
                      retVal = retVal + parseInt(dataitem.qty);
                    });
                }
            });          
            return retVal;
        },
        activeOrder(tableno) {
            var retVal = false;
            this.opencarts.forEach(itemCart => {
                if (tableno == itemCart.table_name) {
                  if (itemCart.data.items.length > 0 ) {
                    console.log(tableno ,itemCart.table_name );
                    retVal=true;
                  }
                }
            });          
            return retVal;
        },
        cancelCart() {
            this.$emit('doCancelStartCart')
        },
        tableSelect(tablename) {

            console.log(this.opencarts);

            // Look for an existing cart already open 
            var existingTableCart= false;
            var existingCart = [];
            this.opencarts.forEach(itemCart => {
                if (tablename == itemCart.table_name) {
                    existingTableCart= true;
                    existingCart = itemCart;
                }
            });

            if (!existingTableCart) {

              this.tables.forEach(table => {
                  if (tablename == table.name) {
                    // Send to back end to get the ID
                    HTTP.post(
                        'api/cart/create',
                        {
                            table_id: table.id,
                            table_name: table.name,
                            service_charge: table.service_charge
                        },
                        {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.token
                            }
                        }   
                        )
                    .then(response => {
                        
                        console.log(response.data)

                        this.$emit('onStartCart',response.data.cart);


                    })
                    .catch(e => {

                        console.log(e);

                        this.$notify({
                            group: 'main',
                            title: 'Sorry Error Assigning Table',
                            type: 'warn',
                            position: 'bottom left'
                        });        

                    })            


                  }
              });

            } else {

              this.$emit('onExistCart',existingCart);

            }


        }
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        }
      }
    }    
  }
</script>

<style>
    #bv-start-cart-modal .modal-content {
        background-color: #2e3442 !important;
    }

    #bv-start-cart-modal .modal-footer {
        border-top: 1px solid #2e3442;
        display:none;
    }

    .startCartSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
    }

    #bv-start-cart-modal .modal-header {
        border-bottom: 1px solid #2e3442;
    }
    #bv-start-cart-modal h4 {
        color:white;
    }

    .modal-xl {
      max-width: 800px !important;
    }

    .tableImageDiv {
      width:750px;
      height:496px;
      background-size: cover;

    }
    .startCartModelButton {
        width:100px;
        border-radius: 10px !important;
        margin:5px;
        font-size: 1.8rem !important;
        background-color:#b0996f !important;
        color:white !important;
        border-color: #b0996f !important;        
    }
    .tableBox {
      width:150px;
      height: 150px;

    }

    .activeTable {
      background-color:yellow;
      opacity: 0.5;
      border-radius: 10px;
    }

    .tableStatus {
      text-align: center;
      position :absolute;
      bottom:0;
      width:100%;
      background-color: green;
      opacity: 1 !important;
      z-index: 99;
      color:white;

    }

    #table1 {
      position: absolute;
      left:2%;
      top:50%;
    }

    #table2 {
      position: absolute;
      left:2%;
      top:10%;
    }


    #table3 {
      position: absolute;
      left:30%;
      top:30%;
    }
    #table4 {
      position: absolute;
      left:55%;
      top:10%;
    }
    #table5 {
      position: absolute;
      left:78%;
      top:10%;
    }
    #table6 {
      position: absolute;
      left:78%;
      top:50%;
    }
    #table7 {
      position: absolute;
      left:55%;
      top:50%;   
    }

    #table8 {
      position: absolute;
      left:80%;
      top:52%;
    }
    #table9 {
      position: absolute;
      left:60%;
      top:52%;
    }



</style>