
<template>
  <div v-bind:class="halfheight" :style="{backgroundColor:getFoodColorBackground,color:getFoodColorForground}">
    <div class="foodSummaryContent" @click="addfood(food,1)">      
        <div class="foodSummaryDescptionHolder">
              <div class="foodSummaryDescption foodSummaryDescptionBoldtitle">{{getDescription(food)}}</div>
        </div>
        <div class="foodSummaryPrice">{{getPrice(food)}}</div>

    </div>
  </div>
</template>

<script>

  import defaults from '../../plugins/defaults'


  export default {
    props: {
      food: Object,
      cart: Object,
    },   
    data () {  
        return {
            colors:defaults.COLORS,
            size:defaults.SIZES
        };    
    },     
    methods: {

        getDescription(food) {            
            var foodName = food.name;

            return foodName;
        },
        getPrice(food) {
            var foodPrice = 'Comp';

            if (parseFloat(food.price) > 0 ) {
              foodPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(food.price));
            }
            

            return foodPrice;
        },       
        addfood(food,moveby) {
          console.log(food,moveby);
          this.$emit('adjustCart', food, moveby, 'food');
        },
    },
    computed: {
        getFoodColorBackground() {
            var color = parseInt(this.food.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].back + " !important";
        },    
        getFoodColorForground() {
            var color = parseInt(this.food.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].color + " !important";
        },    
        halfheight() {
            if ( this.food.size == 2) {
              return  'foodSummarySquare2 foodSummaryType' + this.food.size
            } else {
              return 'foodSummarySquare1 foodSummaryType' + this.food.size
            }
        },           
    }
  }

</script>


<style>

  .foodSummaryHolder {
    background-color: #2e3444;
    border-radius: 1vw;
  }

  .foodSummaryDescptionHolder {
    border:0.3vw solid transparent;
    min-height:20vh;
    overflow: hidden;
  }

  .foodSummaryDescption {
      font-weight: 500;
      font-size:1.2rem;
  }
  .foodSummaryDescption b {
  }

  .foodSummaryPrice {
      padding-top:10px;
      font-weight: 700;
      font-size:1.1vw;
    position: absolute; 
    bottom: 0px;
    right: 0px; 
      margin:10px;   
  }
  
  .foodSummaryDescptionBoldtitle {
      font-size:1.4vw;
      font-weight: 700;
      line-height: 1.4vw;
  }

  .fooddescriptionholder {
    display: flex;
    flex-direction: column;
    border-bottom:20px solid transparent;
    border-right:20px solid transparent;
  }

  .foodSummarySquare1 {
    position: relative;
    width: 100%;
    border-radius: 10px;
  }

  .foodSummarySquare1:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .foodSummarySquare2 {
    position: relative;
    width: 100%;
    border-radius: 10px;
  }

  .foodSummarySquare2:after {
    content: "";
    display: block;
    padding-bottom: 43%;
  }

  .foodSummaryContent {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .foodSummaryType1,.foodSummaryType2 {
        border:0.8vw solid transparent;
  }

  .foodSummaryType3 {
      border-top:0.6vw solid transparent;
  }

  .foodSummaryType3 .foodSummaryDescptionBoldtitle {
      font-size:0.9vw;
      font-weight: 700;
      line-height: 1vw;  }

</style>
