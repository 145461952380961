
<template>
  <div v-bind:class="halfheight" :style="{backgroundColor:getdrinkColorBackground,color:getdrinkColorForground}">
    <div class="drinkSummaryContent" @click="pickDrink()">      
        <div class="drinkSummaryDescptionHolder">
              <div class="drinkSummaryDescption drinkSummaryDescptionBoldtitle">{{getDescription(drink)}}</div>
        </div>
        <!--<div class="drinkSummaryPrice">{{getPrice(drink)}}</div>-->

    </div>
      <drink-select-modal
        :showdialog="drinkSelectVisible"
        :drink="drink"
        :title="getDescription(drink)"
        v-on:doCancelPick="doCancelPick"
        v-on:doPickDrink="doPickDrink"
    /> 
  </div>
</template>

<script>

  import DrinkSelectModal from './drinkSelectModal.vue';
  import defaults from '../../plugins/defaults'  

  export default {
    components: {
      DrinkSelectModal
    },
    props: {
      drink: Object,
      cart: Object,
    },   
    data () {  
        return {
            colors:defaults.COLORS,
            size:defaults.SIZES,
            drinkSelectVisible:false
        };        
    },     
    methods: {

        pickDrink() {
          this.drinkSelectVisible = true;
        },
        doPickDrink(price,size) {
          
          this.drinkSelectVisible = false;
          const tempDrink = {
            name: this.drink.name  + ' ' + size,
            price: price,
            id: this.drink.id,

          }
          this.adddrink(tempDrink,1);
        },
        doCancelPick() {
          this.drinkSelectVisible = false;
        },

        getDescription(drink) {            
            var drinkName = drink.name;

            var extras = "";

            
            if (drink.wine_color.length>0) { 
                extras = extras + ' ' + drink.wine_color;
            }
            if (drink.year.length>0) { 
                extras = extras + ' ' + drink.year;
            }
            
            if (drink.bottle_size.length>0) { 
                extras = extras + ' ' + drink.bottle_size;
            }
                    
            if (extras  !== '') {
              drinkName = drinkName + '(' + extras + ' )';
            }


            return drinkName;
        },
        getPrice(drink) {
            var drinkPrice = 'Comp';

            if (parseFloat(drink.price) > 0 ) {
              drinkPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(drink.price));
            }
            

            return drinkPrice;
        },       
        adddrink(drink,moveby) {
          console.log('adjustCart drink',drink,moveby);
          this.$emit('adjustCart', drink, moveby, 'drink');
        },
    },
    computed: {
        getdrinkColorBackground() {
            var color = parseInt(this.drink.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].back + " !important";
        },    
        getdrinkColorForground() {
            var color = parseInt(this.drink.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].color + " !important";
        },    
        halfheight() {
            console.log(this.drink); 
            if ( this.drink.size == 2) {
              return  'drinkSummarySquare'
            } else {
              return 'drinkSummarySquare'
            }
        },           
    }
  }

</script>


<style>

  .drinkSummaryHolder {
    background-color: #2e3444;
    border-radius: 1vw;
  }

  .drinkSummaryDescptionHolder {
    border:1vw solid transparent;
    min-height:20vh;
  }

  .drinkSummaryDescption {
      font-weight: 500;
      font-size:1.2rem;
  }
  .drinkSummaryDescption b {
  }

  .drinkSummaryPrice {
      padding-top:10px;
      font-weight: 700;
      font-size:1.1vw;
    position: absolute; 
    bottom: 0px;
    right: 0px; 
      margin:10px;   
  }
  
  .drinkSummaryDescptionBoldtitle {
      font-size:1.12vw;
      font-weight: 700;
      line-height: 1.6vw;
  }

  .drinkdescriptionholder {
    display: flex;
    flex-direction: column;
    border-bottom:20px solid transparent;
    border-right:20px solid transparent;
  }

  .drinkSummarySquare {
    position: relative;
    width: 100%;
    border-radius: 10px;
  }

  .drinkSummarySquare:after {
    content: "";
    display: block;
    padding-bottom: 25%;
  }

  .drinkSummaryContent {
    position: absolute;
    width: 100%;
    height: 100%;
  }


</style>
