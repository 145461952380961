
<template>
  <div class="cartlistpadded">
      <h4>Open Carts / Orders</h4>

      <br/>
      <b-table-simple responsive class="cartlistTable">
        <b-thead>
          <b-tr class="cartlistHeader">
            <b-th>Table No</b-th>
            <b-th>Order No</b-th>
            <b-th>No Items</b-th>
            <b-th>Subtotal</b-th>
            <b-th>Service</b-th>
            <b-th>Total</b-th>
            <b-th></b-th>
            <b-th></b-th>            
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr  :key="index" v-for="col,index in cartList" class="cartlistBody" >
            <b-td>{{cartList[index].table_name}}</b-td>
            <b-td>{{cartList[index].id}}</b-td>
            <b-td>{{totalItems(cartList[index])}}</b-td>
            <b-td>{{totalShowPrice(cartList[index])}}</b-td>
            <b-td>{{serviceCharge(cartList[index])}}</b-td>
            <b-td>{{grandTotal(cartList[index])}}</b-td>
            <b-td><b-button class="cartlistActionButton" v-on:click="selectCart(cartList[index])">Open</b-button></b-td>
            <b-td><b-button variant="danger" v-on:click="deleteCart(cartList[index])" v-if="userlevel=='admin'">Delete</b-button></b-td>
          </b-tr>
        </b-tbody>        

      </b-table-simple>
      <h4 v-if="cartList.length===0">
          No Open orders
      </h4>
  </div>
</template>

<script>

  export default {
    props: {
      cartList: Array,
      userlevel: String,
    },
    data () {  
        return {
        };    
    },       
    components: {
    },     
    mounted() {   
    },
    methods: { 

        selectCart(cart) {
          console.log(cart.data);          
          this.$emit('selectCart', cart);

        },
        deleteCart(cart) {          
          this.$emit('deleteCart', cart.id);
        },        
        totalItems(cart) {


            var totalItems = 0;
            console.log(cart.data);
            if (cart.data) {
              if (cart.data.items) {
                cart.data.items.forEach(shoppingcartItem => {
                    totalItems = totalItems + parseInt(shoppingcartItem.qty);
                });
              }
            } 

            return totalItems;

        },      


        totalPrice(cart) {

            var totalPrice = 0;

            if (cart.data) {
              if (cart.data.items) {
                cart.data.items.forEach(shoppingcartItem => {
                    totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
                });
              }
            }
            return totalPrice;

        },   

        totalShowPrice(cart) {

            var totalPrice = this.totalPrice(cart);

            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(totalPrice));

        },   
        
        serviceCharge(cart) {

            var totalPrice = this.totalPrice(cart);

            var serviceCharge = totalPrice  * parseFloat(parseFloat(process.env.VUE_APP_SERVICE_CHARGE) / 100);

            if (cart.pricesOverride) {
                serviceCharge = parseFloat(cart.override.serviceCharge);
            }            
            
            return serviceCharge;

        },
        grandTotal(cart) {

            var grandTotal = this.totalPrice(cart);
            var serviceCharge = this.serviceCharge(cart);
            
            console.log('cart.serviceCharge');
            console.log(cart);
            if (cart.service_charge) {
                grandTotal = grandTotal + serviceCharge;
            }

            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(grandTotal));

        },        

    },    
    computed: {      
    }        
  }

</script>
<style>
    .cartlistpadded {
        border:1vh solid transparent;
        width:100%;
    }
    .cartlistpadded h4 {
      color:white;
    }
    .cartlistHeader {
        color:white;
    }
    .cartlistBody {
      color:white;
      font-size:3vh;
    }
    .cartlistBody tr:hover {
      color:white !important;
    }
    .cartlistActionButton {
        background-color: #b0996f !important;
        color: white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }   
    .cartlistTable .table{
      width:100%
    } 


</style>