
<template>
  <div class="center">
    <b-modal id="bv-login-modal" no-close-on-backdrop v-model="show">
      <template #modal-title>
        <h4 class="not-margin">
          Please login by entering your terminal ID
        </h4>
      </template>


      <div class="con-form">

        <b-row>
          <b-col cols="6">

            <b-input-group size="sm" class="mb-2">
              <b-form-input
                id="login-terminal-id"
                ref="loginterminalid"
                v-model="$v.form.terminalId.$model"
                placeholder="Terminal ID"
                :state="validateState('terminalId')"
                type="number"
                size="lg"
                required
              ></b-form-input>
            </b-input-group>
          </b-col>

          <b-col cols="6">
            <b-button block @click="handleSubmit" class="login-form-button-signin">
              Sign In
            </b-button>                           
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <div class="footer-dialog">

        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  import { validationMixin } from 'vuelidate';
  import { required } from "vuelidate/lib/validators";


  export default {
    mixins: [validationMixin], 
    props: {
      showdialog: Boolean
    },   
    data() {
      return { 
        form: {
          terminalId: '',
          terminalPassword: '',
        }
      }
    },   
    validations: {
      form: {
        terminalId: {
          required
        },
      }
    },       
    mounted() {

    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },      
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()

        console.log(valid)

        this.terminalIdState = valid
        return valid
      },      
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },      
      handleSubmit() {
        this.$v.form.$touch();
        console.log(this.$v.form);
        if (this.$v.form.$anyError) {
          return;
        }

        this.form.terminalPassword = this.form.terminalId;
        this.$emit('onLoginClick', this.form)

        this.form.terminalPassword = '';
        // Hide the modal manually

      }
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        }
      }
    },  
  }
</script>

<style>
  .modal-header .close {
    display:none;
  }
  .login-form-button-signin {
    width:100%;
  }
</style>