<template>

    <div class="headerholder">
        <b-row>
            <b-col cols="3">
                <div class="headerFixButtons">


                <b-button-group class="headerButtonSwitch" v-if="currentRouteName == 'home'">
                    <b-button
                        :pressed.sync="showWine"
                        variant="primary"
                        v-on:click="toggleWine(true)"
                        :class="[showWine ? 'headerButtonSwitchActive' : 'headerButtonSwitchDisabled']"
                    >
                        Wine List
                    </b-button>
                    <b-button
                        :pressed.sync="showFoodDrink"
                        variant="primary"
                        v-on:click="toggleWine(false)"
                        :class="[showFoodDrink ? 'headerButtonSwitchActive' : 'headerButtonSwitchDisabled']"                      
                    >
                        Food and Drink
                    </b-button>
                </b-button-group>
                
                <div class="headerSearch">

                    <b-input-group sclass="mb-2" v-if="currentRouteName == 'home'">
                        <b-input-group-prepend is-text class="headerSearchInputIcon">
                            <b-icon icon="search" class="headerSearchInputIconIcon"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                            id="login-terminal-id"
                            placeholder="Search"
                            class="headerSearchInput"
                            v-model="search"
                        ></b-form-input>
                    </b-input-group>                      
                </div>      
                </div>          
            </b-col>
            <b-col cols="6">
                <h1 class="headerSystemText"><img src="../../assets/oeno-logo.png" style="height:2vw;margin-top:-5px;"/> {{SystemName}} <span class="headerVersionText">v{{SystemVersion}} ({{userpin}} - {{userlevel}})</span></h1>
                <h1 class="headerOrderText" v-if="cart.cartVisble"><span class="headerOrderTextSmall">Order No :</span> {{cart.orderNo}} <span class="headerOrderTextSmall"> | Table No : </span> {{cart.tableNo}}</h1>
            </b-col>
            <b-col cols="3">

                <b-row>

                    <b-col w="2">
                        <div class="headerIconHolder"
                            v-on:click="doStartCart()" v-if="currentRouteName == 'home'">
                            <font-awesome-icon icon="plus-square" class="headericon" />
                        </div>
                    </b-col>
                    <b-col w="2">
                        <div class="headerIconHolder" v-if="currentRouteName == 'home'">
                            <router-link to="/carts"><font-awesome-icon icon="shopping-cart" class="headericon" /></router-link>
                        </div>
                        <div class="headerIconHolder" v-if="currentRouteName != 'home'">
                            <router-link to="/"><font-awesome-icon icon="home" class="headericon" /></router-link>
                        </div>
<!--                        <vs-button
                            icon
                            color="transparent"
                            flat
                            
                        >
                            <font-awesome-icon icon="shopping-cart" class="headericon" v-if="wineListVisble" />
                            <font-awesome-icon icon="wine-glass" class="headericon" v-if="!wineListVisble" />
                            <div class="cartCount" v-if="totalItems>0">{{totalItems}}</div>
                        </vs-button>

-->                        
                    </b-col>
                    <b-col w="2" v-if="userlevel=='admin' && currentRouteName == 'home'">
                        <div class="headerIconHolder">
                            <router-link to="/admin"><font-awesome-icon icon="cogs" class="headericon" /></router-link>
                        </div>
                    </b-col>
                    <b-col w="2">
                        <div class="headerIconHolder">
                            <font-awesome-icon icon="user" class="headericon" v-on:click="doLogout"/>
                        </div>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>    
    </div>

</template>

<script>

  export default {
    props: {
      cart:Object,
      wineListVisble:Boolean,
      userlevel:String,
      userpin:String,
    },
    data () {  
        return {
            SystemName:process.env.VUE_APP_NAME,
            SystemVersion:process.env.VUE_APP_VERSION,
            showWine:true,
            showFoodDrink:false,
            search:'',
        }
    },
    methods: {
        toggleWine(val) {
            this.showWine = val;
            this.showFoodDrink = !val;
            this.$emit('changeFoodDrinkSwitch',this.showWine,this.showFoodDrink );
        },
        showCart() {
            if (this.wineListVisble) {
                if (this.totalItems > 0) {
                    this.$emit('showCart');
                }
            } else {
                this.$emit('hideCart');
            }            
        },
        doStartCart() {
            this.$emit('doStartCart');            
        },
        actiontoggle() {
            this.showWine = !this.showWine;
        },
        doSearchChange() {
            this.$emit('doSearchChange',this.search  );
        },
        doLogout() {
            console.log('dologout')
            this.$emit('doLogout');
        },

    },
    computed: {
        totalItems() {
            var totalItems = 0;
            this.cart.items.forEach(item => {
                totalItems = totalItems + parseInt(item.qty);
            });
            return totalItems;
        },
        currentRouteName() {
            console.log(this.$route);
            return this.$route.name;
        }        
    },
    watch: {
        search: function() {
            this.doSearchChange()
        },
    }        
  }

</script>

<style>

   .headerButtonSwitch,.headerSearch {
       width:100%;
       padding-top:0.5vw;
       padding-left:0.4vw;
       padding-right:0;
       padding-bottom: 0.8vw;

   }


    .headerButtonSwitchActive {
        background-color:#b0996f !important;
        color:white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }

    .headerButtonSwitchDisabled {
        background-color: #2e3444 !important;
        color:#7f838e !important;
        border-color: #2e3444 !important;
        font-size: 1rem !important;
    }

    .headerSearchInput {
        background-color: #2e3444 !important;
        color:#9a9da7 !important;
        border-color: #2e3444 !important;     
    }

    .headerSearchInputIcon .input-group-text {
         background-color: #2e3444 !important;       
         border: 1px solid #2e3444;

    }

    .headerSearchInputIconIcon {
        color:#9a9da7 !important;
        height: 1.5rem;       
    }

    .headerSystemText {
        color:#7f838e;
        margin-bottom: 0.8vw;
        margin-top: 0.8vw;
        margin-left: 2vw;
    }

    .headerVersionText {
        font-size: 0.6vw;
        color:#7f838e;
    }

    .headerOrderText {
        color:white;
        margin-top: 0.8vw;
        margin-left: 2vw;        
    }
    .headerOrderTextSmall {
        font-size:1rem;
        color:#9a9da7
    }

    .headerIconHolder {
       padding:0.5vw;
       cursor:pointer;
    }
    .headericon {
        font-size: 2vw;
        color:white;
    }
    .headerFixButtons {
        min-width: 24.5vw;
        width: 24.5vw !important;
        padding-right:0;
    }

</style>