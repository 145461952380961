<template>
<div>

    <div class="filterBoxWineType">
        <p class="filterBoxTitle">Wine Type</p>
        <b-row class="filterrowpadding">
            <b-col cols="4" :key="i" v-for="(wt, i) in winetypes" class="sidebarPaddBelow">

                    <b-button
                        variant="primary"
                        size="sm"
                        :class="[wineTypeSelected(wt.id) ? 'sidebarButtonWinetypeActive' : 'sidebarButtonWinetypeDisabled']"
                        v-on:click="wineTypeSelect(wt.id)"
                    >
                        {{wt.name}}
                    </b-button>
                    

            </b-col>
        </b-row>
    </div>


    <br/><div class="filterBox">
        <p class="filterBoxTitle">Vintage</p>
        <div  class="filterrowpadding sidebarSelectBox">
            <b-form-checkbox-group
                v-model="vintageSelected"
                :options="vintages"
                value-field="name"
                text-field="display"
                class="filterCheckBoxes"
                v-on:change="doFilterChange"
            ></b-form-checkbox-group>
        </div>
    </div>

    <br/><div class="filterBox">
        <p class="filterBoxTitle">Countries</p>
        <div  class="filterrowpadding sidebarSelectBox">
            <b-form-checkbox-group
                v-model="countrySelected"
                :options="countries"
                value-field="id"
                text-field="name"
                class="filterCheckBoxes"
                v-on:change="doFilterChange"
            ></b-form-checkbox-group>
        </div>
    </div>


    <br/><div class="filterBox">
        <p class="filterBoxTitle">Grape</p>
        <div  class="filterrowpadding sidebarSelectBox">
            <b-form-checkbox-group
                v-model="grapeSelected"
                :options="grapeVariety"
                value-field="id"
                text-field="name"
                class="filterCheckBoxes"
                v-on:change="doFilterChange"
            ></b-form-checkbox-group>
        </div>
    </div>


    <br/><div class="filterBox">
        <p class="filterBoxTitle">Size</p>

        <b-row class="filterrowpadding">
            <b-col cols="4" :key="i" v-for="(wt, i) in winesizes" class="sidebarPaddBelow">

                    <b-button
                        variant="primary"
                        size="sm"
                        :class="[wineSizeSelected(wt.id) ? 'sidebarButtonWineSizeActive' : 'sidebarButtonWineSizeDisabled']"
                        v-on:click="wineSizeSelect(wt.id)"
                    >
                        {{wt.name}} ml
                    </b-button>
                    

            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <p class="filterBoxTitle">Price</p>
            </b-col>
            <b-col>
                <p class="filterBoxTitle">{{displayPriceRange()}}</p>
            </b-col>
        </b-row>
        <div class="filterSliderHolder">
            <b-form-slider ref="range" v-model="priceRangeValue" range :min="0" :max="10000" :step="20" trigger-change-event></b-form-slider>
        </div>

        <br/><br/>
    </div>
    <br/><br/><br/><br/><br/><br/><br/>
</div>
</template>
<script>
import bFormSlider from 'vue-bootstrap-slider/es/form-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';

export default {
    components: {bFormSlider},
    props: {
        vintages: Array,
        winetypes: Array,
        winesizes: Array,
        countries: Array,
        grapeVariety: Array,
        searchTerm: String,
    },           
    data:() => ({
        VintageSelected: [],
        countrySelected: [],
        grapeSelected: [],
        search: '',
        winetypeButtonsSelected:[],
        winesizeButtonsSelected:[],
        vintageSelected:[],
        priceRangeValue: [0, 10000],        
    }),
    methods: {
        displayPriceRange() {
            return '£' + parseInt(this.priceRangeValue[0]) + ' - £' + parseInt(this.priceRangeValue[1]);
        },
        wineTypeSelected(winetypeID) {

            var retVal = false;
            this.winetypeButtonsSelected.forEach(selected => {

                if (selected == winetypeID ) {
                    retVal = true;
                }
                
            });
            return retVal;

        },

        wineTypeSelect(winetypeID) {

            if (!this.wineTypeSelected(winetypeID)) {
                this.winetypeButtonsSelected.push(winetypeID);
            } else {
                var index = this.winetypeButtonsSelected.indexOf(winetypeID);
                if (index !== -1) {
                    this.winetypeButtonsSelected.splice(index, 1);
                }                
            }

        },

        wineSizeSelected(winesizeID) {

            var retVal = false;
            this.winesizeButtonsSelected.forEach(selected => {

                if (selected == winesizeID ) {
                    retVal = true;
                }
                
            });
            return retVal;

        },

        wineSizeSelect(winesizeID) {

            if (!this.wineSizeSelected(winesizeID)) {
                this.winesizeButtonsSelected.push(winesizeID);
            } else {
                var index = this.winesizeButtonsSelected.indexOf(winesizeID);
                if (index !== -1) {
                    this.winesizeButtonsSelected.splice(index, 1);
                }                
            }

        },
                
        doFilterChange() {

            console.log(this.countrySelected,this.vintageSelected)
            this.$emit('doFilterChange', 
                {
                    vintageSelected: this.vintageSelected,
                    winesizeSelected:this.winesizeButtonsSelected,
                    winetypeSelected: this.winetypeButtonsSelected,
                    winePriceRange:this.priceRangeValue, 
                    countrySelected: this.countrySelected,
                    grapeSelected: this.grapeSelected        
                }
            )
        },
        getVitageDescription(name,qty) {
            console.log(name,qty);
            return name + '(' + qty + ')';
        },
    },
    watch: {
        winetypeButtonsSelected: function() {
            this.doFilterChange()
        },
        winesizeButtonsSelected: function() {
            this.doFilterChange()
        },
        priceRangeValue: function() {
            this.doFilterChange()
        },
    }    

}
</script>

<style>

.filterBox, .filterBoxWineType {
    background-color: #2e3442 !important;
    color:#7f838e !important;    
    padding:0.4vw;
    border-radius: 15px;
    font-size: 0.8rem;
padding: 0.8vw;
    padding-right: 1.5vw;    
}

.filterBoxWineType {
    padding:0.8vw;
    padding-right:1.5vw;
}

.filterBoxTitle {
    margin:0;
    margin-left: 10px;
    color:white;
    font-size: 1rem;
    border-bottom: 10px solid transparent;
}

.filterrowpadding {
    padding-right:0.4vw;   
    padding-top:0.4vw;   
    padding-bottom:0vw;   
}

.sidebarButtonWinetypeActive ,.sidebarButtonWinetypeDisabled {
    width:7vw;
    border-radius: 10px !important;
    margin:5px;
    font-size: 1vw !important;
    padding-left:0.5vw !important;
    padding-right:0.5vw !important;
    border:2px solid #7f838e !important;
} 

.sidebarButtonWinetypeActive {
    background-color:#b0996f !important;
    color:white !important;
    border-color: #b0996f !important;
}

.sidebarButtonWinetypeDisabled {
    background-color: #2e3444 !important;
    color:white !important;
    border-color: #7f838e !important;
}

.sidebarButtonWineSizeActive ,.sidebarButtonWineSizeDisabled {
    width:7vw;
    height:40px;
    border-radius: 10px !important;
    margin:5px;
    font-size: 1vw !important;

    border:2px solid #7f838e !important;
} 

.sidebarButtonWineSizeActive {
    background-color:#b0996f !important;
    color:white !important;
    border-color: #b0996f !important;
}

.sidebarButtonWineSizeDisabled {
    background-color: #2e3444 !important;
    color:white !important;
    border-color: #7f838e !important;
}

.sidebarSelectBox {
    padding:0.5vw;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 150px;    
}

.filterCheckBoxes .custom-control-label {
    font-size: 1rem;
    margin-left:10px;
}

.filterCheckBoxes .custom-checkbox {
    padding-bottom:10px;
}

.filterCheckBoxes .custom-control-input {
  width:20px;
  height:20px;
}


.filterCheckBoxes .custom-control-input:checked:after {
    background-color:green;
}

.sidebarPaddBelow {
    border-bottom: 0vw solid transparent;
    padding-left: 0.4vw;
    padding-right: 0px;
}



.sidebarSelectBox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.sidebarSelectBox label {
  position: relative;
  cursor: pointer;
}

.sidebarSelectBox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.sidebarSelectBox input:checked + label:after {
  content: '✔';
  position: absolute;
  top: 0px;
  left: 0px;
  padding-left:5px;
  padding-top:2px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  color:white !important;
  background-color: #b0996f;
}

.filterSliderHolder {
    padding-left:20px;
}

.slider {
    width:18vw !important;
}

</style>